import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card as MuiCard,
  CardProps as MuiCardProps,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Paper,
  List,
} from "@mui/material";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

const CardContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  cursor: "move",
  userSelect: "none",
  border: "none",
});

interface CardProps extends MuiCardProps {
  draggable?: boolean;
  onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void;
}

const Card = styled(MuiCard)<CardProps>(({ draggable }) => ({
  opacity: draggable ? 0.5 : 1,
  height: 100,
  width: 400,
  position: "relative",
}));

interface PopupMenuProps {
  closePopup: () => void;
  images: string[];
}
const PopupMenu: React.FC<PopupMenuProps> = ({ closePopup, images }) => {
  const [cards, setCards] = useState<string[]>(images);

  const [defaultCards, setDefaultCards] = useState<string[]>([]);

  useEffect(() => {
    setDefaultCards(cards);
  }, []);
  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.dataTransfer.setData("index", String(index));
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    position: number
  ) => {
    const index = Number(event.dataTransfer.getData("index"));
    const updatedCards = cards.filter((card, i) => i !== index && card);
    updatedCards.splice(position, 0, cards[index]);
    setCards(updatedCards);
  };

  return (
    <>
      <CardContainer>
        <Paper
          style={{
            maxHeight: "calc(70vh - 16px)",
            width: "100%",
            overflow: "auto",
            border: "none",
          }}
        >
          <List
            sx={{
              p: 2,
            }}
          >
            <Grid container spacing={2}>
              {cards.map((card, index) => (
                <Grid item key={index}>
                  <div
                    onDrop={(event) => handleDrop(event, index)}
                    onDragOver={(event) => event.preventDefault()}
                  >
                    <Card
                      draggable
                      onDragStart={(event) => handleDragStart(event, index)}
                    >
                      <DeleteIcon
                        onClick={() => {
                          const updatedCards = cards.filter(
                            (card, i) => i !== index && card
                          );
                          setCards(updatedCards);
                        }}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                        }}
                      />
                      <Typography variant="body1">Image {index+1}</Typography>
                      <img
                        src={card}
                        alt=""
                        style={{ height: 100 }}
                      />
                    </Card>
                  </div>
                </Grid>
              ))}
            </Grid>
          </List>
        </Paper>
      </CardContainer>
      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          mt: 2,
        }}
      >
        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Перевірено" />
          </FormGroup>
        </Grid>
        <Grid item xs={5}>
          <Button onClick={closePopup} variant="contained" color="success">
            Зберегти
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setCards(defaultCards)}
          >
            Відмінити
          </Button>
        </Grid>
        <Grid item xs={8}>
          <div className="relative">
            <Typography>Додати нове фото</Typography>
            <input
              type="file"
              accept=".png, .webp"
              onChange={async (event) => {
                try {
                  const file = (event.target as HTMLInputElement).files?.item(
                    0
                  );
                  // TODO: upload image
                } catch (err) {}
              }}
              className="w-full absolute inset-0 opacity-0"
            />
          </div>{" "}
        </Grid>
      </Grid>
    </>
  );
};

export default PopupMenu;
