import React, {useState} from "react";
import {Box, Grid, MenuItem, Modal, Select, Typography,} from "@mui/material";
import PopupMenu from "../../components/parser/popup_menu";
import {useGetParsedDataQuery} from "../../services/data";
import {useDispatch, useSelector} from "react-redux";
import {setEditMode, setShowingMode,} from "../../redux/slices/ModalPopupSlice";
import {ParserData, ParserDataSerialized} from "../../types";
import {PARSED_AD_FILTERS, ParsedAdSortOrderDate, ParsingProps} from "../../types/parsed_at";
import {ParsingResultItem} from "./parsing_result_item";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SORT_FUNCS: Record<ParsedAdSortOrderDate,(a: ParserDataSerialized, b: ParserDataSerialized) => number> = {
  'new': (a, b)=>{
    return Date.parse(b.created_at) - Date.parse(a.created_at);
  },
  'old': (a, b)=>{
    return Date.parse(a.created_at) - Date.parse(b.created_at);
  },
  'new_olx': (a, b)=>{
    return Date.parse(b.source_created_at) - Date.parse(a.source_created_at);
  },
  'old_olx': (a, b)=>{
    return Date.parse(a.source_created_at) - Date.parse(b.source_created_at);
  },
}
// TODO: This is temporary version of serializer.
const serializeResponse = (
  dataArray: ParserData[],
  sortOrder: {date: ParsedAdSortOrderDate }
): Partial<ParserDataSerialized>[] => {
  return dataArray.map(
    (pd): ParserDataSerialized => ({
      id: pd?.id,
      district: pd?.district,
      street: pd?.street,
      residential_complex: pd?.residential_complex,
      building_number: pd?.building_number,
      is_center: pd?.is_center,
      newly_built: pd?.newly_built,
      side: pd?.side,
      metro_stations: pd?.metro_stations,
      rooms: pd?.rooms,
      living_area: pd?.living_area,
      floor: pd?.floor,
      amount_of_floors: pd?.amount_of_floors,
      rent_price: pd?.rent_price,
      currency: pd?.currency,
      contact_name: pd?.contact_name,
      phone_number: pd?.phone_number,
      ownership_status: pd?.ownership_status,
      kids: pd?.kids,
      pets: pd?.pets,
      geo: pd?.geo,
      origin: pd?.origin,
      origin_id: pd?.origin_id,
      source_link: pd?.source_link,
      source_label: pd?.source_label,
      ad_mode: pd?.ad_mode,
      created_at: pd?.created_at,
      source_created_at: pd?.source_created_at,
      source_id: pd?.source_id,
      images: pd?.images,
    })
  ).sort(SORT_FUNCS[sortOrder.date]);
};

const ParsingResult = (props: ParsingProps) => {
  const { title, filter } = props;

  // Generate the filter parameters based on filter prop

  const filterParams = PARSED_AD_FILTERS[filter];
  // Fetch data from the API
  let { data: resultData } = useGetParsedDataQuery(filterParams); // Pass the filterParams object directly
  if (!resultData) {
    resultData = [];
  }
  // Serialize the response
  const [sortOrder, setSortOrder] = useState<{date: ParsedAdSortOrderDate}>({
    date: 'new',
  });
  const data = serializeResponse(resultData, sortOrder);
  const modals = useSelector((state: any) => state.modals);
  const toggleFilter = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const closeEditMode = async () => {
    await dispatch(setEditMode(false));
  };
  const closeShowingMode = async () => {
    await dispatch(setShowingMode(false));
  };
  const TextFieldPropsForFilter = {
    style: {
      // TODO Sizes
    },
  };

  if (!data.length) {
      return(<Typography variant="h4">Немає записів</Typography>);
  }

  return (
    <>
      <Typography variant="h4" sx={{marginBottom: 1, marginTop: 1}}>
        {title}
      </Typography>
      <Grid container>
        <Grid item xs={2}>
          <Select
            size="small"
            value={sortOrder.date}
            onChange={(e) =>
              setSortOrder((prevState) => ({
                ...prevState,
                date: e.target.value as ParsedAdSortOrderDate,
              }))
            }
          >
            <MenuItem value={'new'}>Спочатку нові</MenuItem>
            <MenuItem value={'old'}>Спочатку старі</MenuItem>
            <MenuItem value={'new_olx'}>Спочатку нові (olx)</MenuItem>
            <MenuItem value={'old_olx'}>Спочатку старі (olx)</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
          {data?.map((column, index) => {
            if (index === 0) {
              console.log("column", column);
            }
            if (
              !toggleFilter?.toggleTypeOfProperty
                ? "rent"
                : "sales" === column.ad_mode
            ) {
              return (
                <Grid item key={index} style={{width: "100%"}}>
                  <ParsingResultItem key={column.id || index} column={column}></ParsingResultItem>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>

      <Modal
        open={modals?.editMode}
        onClose={closeEditMode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PopupMenu closePopup={() => closeEditMode} images={modals?.images}/>
        </Box>
      </Modal>
      <Modal
        open={modals?.showingMode}
        onClose={closeShowingMode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe src={modals?.showingModeURL} width="100%" height="800px"/>
        </Box>
      </Modal>
    </>
  );
};
export default ParsingResult;
