import React from "react";
import { Grid } from "@mui/material";
import DashboardItem from "../components/DashboardItem";
import {useGetParsedDataQuery} from "../services/data";

const HomePage = () => {
  let { data: resultData } = useGetParsedDataQuery({object_id: "none"});
  const parsedItemsCount = resultData?.length || 0;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "90vh",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 8, md: 8 }}
      >
        <DashboardItem title="Парсер" value={parsedItemsCount.toString()} href="/parser" />
        <DashboardItem title="База" value="4000" href="/base" />
      </Grid>
    </div>
  );
};
export default HomePage;
