import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_API_URL, getAuthHeader } from "../api/server";
import { ParserData } from "../types";
import { ObjectModel } from "../types/objects";

export const parsedAdApi = createApi({
  reducerPath: "parsedAdApi",
  baseQuery: fetchBaseQuery({
    baseUrl: DEFAULT_API_URL,
  }),
  endpoints: (builder) => ({
    getParsedData: builder.query<ParserData[], Record<string, string>>({
      query: (params) => {
        const url = new URL("/parsed-ad/", DEFAULT_API_URL);

        // Append each parameter to the URL
        for (const [key, value] of Object.entries(params)) {
          url.searchParams.append(key, value);
        }

        return {
          url: url.toString(),
          method: "GET",
          headers: {
            Authorization: getAuthHeader(),
          },
        };
      },
    }),
    sendParsedData: builder.mutation<string, ParserData>({
      query: (data) => ({
        url: "/sendParsedData",
        method: "POST",
        body: data,
        headers: {
          Authorization: getAuthHeader(),
        },
      }),
    }),
  }),
});

export const {
  useGetParsedDataQuery,
  useSendParsedDataMutation,
} = parsedAdApi;

export const objectApi = createApi({
  reducerPath: "objectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: DEFAULT_API_URL,
  }),
  endpoints: (builder) => ({
    getParsedData: builder.query<ObjectModel[], Record<string, string>>({
      query: (params) => {
        const url = new URL("/objects/", DEFAULT_API_URL);

        // Append each parameter to the URL
        for (const [key, value] of Object.entries(params)) {
          url.searchParams.append(key, value);
        }

        return {
          url: url.toString(),
          method: "GET",
          headers: {
            Authorization: getAuthHeader(),
          },
        };
      },
    }),
  }),
});
