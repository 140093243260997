import React from "react";
import {Grid} from "@mui/material";
import DashboardItem from "../../components/DashboardItem";
import {useGetParsedDataQuery} from "../../services/data";
import {PARSED_AD_FILTERS, ParsedAdFilter} from "../../types/parsed_at";

interface MenuItem {
    title: string;
    href: string;
    filter: ParsedAdFilter;
}

const MENU_ITEMS: MenuItem[] = [
    {
        title: "Всі Оголошення",
        href: "/parser/total_parsed",
        filter: "all",
    },
    {
        title: "Оголошення від власників",
        href: "/parser/from_owner",
        filter: "owners",
    },
    {
        title: "Ціна вища",
        href: "/parser/price_up",
        filter: "priceChangeUp",
    },
    {
        title: "Ціна нища",
        href: "/parser/price_down",
        filter: "priceChangeDown",
    },
    {
        title: "Відкладені",
        href: "/parser/postponed",
        filter: "postponed",
    },
    {
        title: "Смітник",
        href: "/parser/garbage_collector",
        filter: "trash",
    },

]

const DashboardItemWrapper = ({item}: { item: MenuItem }) => {
    let {data: data} = useGetParsedDataQuery(PARSED_AD_FILTERS[item.filter]);
    const count = data?.length || 0;
    return (<DashboardItem
        title={item.title}
        value={count.toString()}
        href={item.href}
    />)
}

const ParserPage = () => {
    return (<div style={{
            display: "flex",
            justifyContent: "center",
            height: "70vh",
            alignItems: "center",
        }}>
        <Grid container spacing={1} direction="row" sx={{mt: 1}}>
            {MENU_ITEMS.map(item => <DashboardItemWrapper item={item}></DashboardItemWrapper>)}
        </Grid>
    </div>);
};

export default ParserPage;
