import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Link,
    Paper,
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    useTheme,
    Autocomplete,
    Select,
    MenuItem
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import {setEditImages, setEditMode, setShowingModeURL} from "../../redux/slices/ModalPopupSlice";
import React, {FC, useState} from "react";
import {ParserData, ParserDataSerialized} from "../../types";
import {useDispatch} from "react-redux";
import Carousel from "react-material-ui-carousel";
import {ImageProps} from "../../types/parsed_at";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import {MetroStation, ObjectModel} from "../../types/objects";
import Masonry from '@mui/lab/Masonry';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";

dayjs.locale('ua');
dayjs.extend(relativeTime);

interface ParsingResultItemProps {
    column: Partial<ParserDataSerialized>;
}

const metroStations = [
  'Академмістечко',
  'Арсенальна',
  'Берестейська',
  'Видубичі',
  'Вокзальна',
  'Героїв Дніпра',
  'Дарниця',
  'Деміївська',
  'Дніпро',
  'Дорогожичі',
  'Житомирська',
  'Золоті Ворота',
  'Кловська',
  'Контрактова площа',
  'Левобережна',
  'Либідська',
  'Лісова',
  'Майдан Незалежності',
  'Мінська',
  'Оболонь',
  'Осокорки',
  'Палац "Україна"',
  'Палац спорту',
  'Печерська',
  'Площа Льва Толстого',
  'Позняки',
  'Сирець',
  'Славутич',
  'Тараса Шевченко',
  'Теремки',
  'Харківська',
  'Чернігівська',
  'Шулявська'
];










function Image({imageUrl, onEdit}: ImageProps) {
    const dispatch = useDispatch();
    const style: any = {
        backgroundColor: "#64ACC8",
        height: 200,
        position: "relative",
    };
    if (imageUrl) {
        style.backgroundImage = `url(${imageUrl})`;
        style.backgroundSize = "200px 200px";
        style.backgroundRepeat = "no-repeat";
    }
    return (
        <Paper className="Project" style={style} elevation={10}>
            <IconButton
                onClick={onEdit}
                sx={{
                    justifyContent: "flex-end",
                    zIndex: 2,
                    position: "absolute",
                    right: 0,
                }}
            >
                <EditIcon/>
            </IconButton>
        </Paper>
    );
}


// function ImagesBlock({images}: { images?: string[] }) {
interface SettingsT {
    autoPlay: boolean;
    animation: "fade" | "slide";
    indicators: boolean;
    duration: number;
    navButtonsAlwaysVisible: boolean;
    navButtonsAlwaysInvisible: boolean;
    fullHeightHover: boolean;
    cycleNavigation: boolean;
    swipe: boolean;

    [key: string]: any;
}

const DefaultSettingsT: SettingsT = {
    autoPlay: false,
    animation: "fade",
    indicators: true,
    duration: 500,
    navButtonsAlwaysVisible: true,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    fullHeightHover: true,
    swipe: true,
};

const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
        event.preventDefault();
    }
};

const ImagesBlock = (props: { images: string | string[]; }) => {
    const dispatch = useDispatch();
    // Assuming `images` is a prop passed to this component
    let imagesArray: string[] = [];

    if (typeof props.images === 'string') {
        try {
            imagesArray = JSON.parse(props.images);
        } catch (error) {
            console.error('Failed to parse images:', error);
        }
    } else {
        imagesArray = props.images as string[];
        console.warn('props.images is not a string:', props.images);
    }

    return (
        <Carousel
            className={"slider"}
            navButtonsWrapperProps={{
                style: {
                    height: 24,
                    position: "absolute",
                    top: "auto",
                    bottom: 16,
                },
            }}
            navButtonsProps={{
                style: {
                    height: 24,
                    width: 24,
                },
            }}
            indicatorIconButtonProps={{
                style: {
                    display: "none",
                },
            }}
            {...DefaultSettingsT}
        >
            {imagesArray && imagesArray.length > 0 ? (
                imagesArray.map((image: string, i: number) => <Image key={i}
                                                                     onEdit={() => {
                                                                         dispatch(setEditImages(imagesArray));
                                                                         dispatch(setEditMode(true));
                                                                     }}
                                                                     imageUrl={image}/>)
            ) : (
                <Image key="no-image" onEdit={() => {
                }}/>
            )}
        </Carousel>
    );
};


interface ActionLinkProps {
    label: string;
    bgColor: string;
    onClick?: () => void;
}

const ActionLink: React.FC<ActionLinkProps> = ({label, bgColor, onClick}) => (
    <Link
        style={{
            color: "#fff",
            textDecoration: "none",
            backgroundColor: bgColor,
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "12px",
            fontWeight: 600,
            textTransform: "uppercase",
        }}
        onClick={onClick}
    >
        {label}
    </Link>


);

interface ColumnDefProps {
    onChange: (value: any) => void;
    title: string;
    options?: string[] | number[];
    value: any;
}
interface CheckboxDefProps {
    title: string;
    value: boolean;
    onChange: (newValue: boolean) => void;
}

interface ColumnDef {
    name: keyof ObjectModel,
    title: string;
    options?: string[] | number[];
    widget: FC<ColumnDefProps>
}

type MetroComponentProps = {
    value: MetroStation[];
    onChange: (newValue: MetroStation[]) => void;
};

const MetroComponent: React.FC<MetroComponentProps> = ({ value, onChange }) => {
    const handleInputChange = (index: number, field: keyof MetroStation, newValue: string) => {
        const updatedValue = [...value];
        updatedValue[index][field] = newValue;
        onChange(updatedValue);
    };

    const addStation = () => {
        const newStation = { name: "", distance: "", time: "" };
        const updatedStations = [...value, newStation];
        onChange(updatedStations);
    };

    return (
        <div>
            {value.map((station, index) => (
                <Grid container spacing={2} key={index}>
                    <Grid item xs={4}>
                        <Autocomplete
                            options={metroStations}
                            value={station.name}
                            onChange={(event, newStation) => handleInputChange(index, 'name', newStation || '')}
                            renderInput={(params) => (
                                <TextField {...params} label="Метро станція" variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Відстань (км)"
                            value={station.distance}
                            onChange={(e) => handleInputChange(index, 'distance', e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Час (хв)"
                            value={station.time}
                            onChange={(e) => handleInputChange(index, 'time', e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            ))}
            <Button variant="contained" color="primary" onClick={addStation}>
                Додати станцію
            </Button>
        </div>
    );
};

const TEXT_WIDGET = (props: ColumnDefProps) => {
    const theme = useTheme();
    return (<div
            style={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <Typography color={props.value ? "textSecondary" : theme.palette.error.light}>
                {props.title}:
            </Typography>
            <TextField value={props.value || ''} margin="none" size="small" variant="standard"
                       onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                           props.onChange(e.target.value);
                       }}>
            </TextField>

        </div>


    );
}

const DISPLAY_WIDGET = (props: ColumnDefProps) => {
    const theme = useTheme();

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <Typography color="textSecondary">
                {props.title}:
            </Typography>
            <Typography style={{marginLeft: 10, fontWeight: 'bold'}}>
                {props.value}
            </Typography>
        </div>
    );
}

const NUMERICAL_WIDGET = (props: ColumnDefProps) => {
    const theme = useTheme();
    return (<div
            style={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <Typography color={props.value ? "textSecondary" : theme.palette.error.light}>
                {props.title}:
            </Typography>
            <TextField value={props.value || ''} margin="none" size="small" variant="standard" type={"number"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                           props.onChange(e.target.value);
                       }}>
            </TextField>

        </div>


    );
}

const URL_WIDGET = (props: ColumnDefProps) => {
    const theme = useTheme();
    const [isURL, setIsURL] = useState<boolean>(true);
    const [isHovered, setIsHovered] = useState<boolean>(true);
    const [value, setValue] = useState(props.value)


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputValue = e.target.value;

        // Simple URL pattern validation
        const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        setValue(inputValue)
        if (!inputValue) {
            props.onChange('')
            setIsURL(false)
            return
        }
        if (urlPattern.test(inputValue)) {
            setIsURL(true);
            props.onChange(inputValue);
        } else {
            setIsURL(false);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <Typography color={props.value && isURL ? "textSecondary" : theme.palette.error.light}>
                {props.title}:
            </Typography>
            <TextField
                value={value || ''}
                margin="none"
                size="small"
                variant="standard"
                error={!isURL}
                helperText={!isURL ? "Please enter a valid URL" : null}
                onChange={handleInputChange}
                onFocus={() => setIsHovered(true)}
                onBlur={() => setIsHovered(false)}
                multiline={isHovered}
            />
        </div>
    );
}


const SELECT_WIDGET = (props: ColumnDefProps) => {
    const theme = useTheme();

    const options = props?.options || [];
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography color={props.value ? "textSecondary" : theme.palette.error.light}>
                {props.title}:
            </Typography>
            <Select
                value={props.value || ''}
                onChange={(e) => {
                    props.onChange(e.target.value as string);
                }}
                variant="standard"
            >
                {options.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
            </Select>
        </div>
    );
};
const CHECKBOX_WIDGET = (props: CheckboxDefProps) => {
    const theme = useTheme();

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Typography color={props.value ? "textSecondary" : theme.palette.error.light}>
                {props.title}:
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                        color="primary"
                    />
                }
                label=""
            />
        </div>
    );
}
const DATE_FROM_NOW_WIDGET = (props: ColumnDefProps) => {
    const theme = useTheme();

    const formattedDate = props.value ? dayjs(props.value).fromNow() : 'Invalid Date';

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <Typography color="textSecondary">
                {props.title}:
            </Typography>
            <Typography style={{marginLeft: 10, fontWeight: 'bold'}}>
                {formattedDate}
            </Typography>
        </div>
    );
}

const COLUMNS: ColumnDef[] = [
    {
        name: 'images',
        title: 'Фото',
        widget: (props: ColumnDefProps) => {
            return (<ImagesBlock images={props.value as string[] || []}/>);
        },
    },
    {
        name: 'district',
        title: 'Район',
        widget: TEXT_WIDGET,
    },
    {
        name: 'is_center',
        title: 'Центр',
        widget: CHECKBOX_WIDGET,
    },
    {
        name: 'newly_built',
        title: 'Новобудова',
        widget: CHECKBOX_WIDGET,
    },
    {
        name: 'street',
        title: 'Вулиця',
        widget: TEXT_WIDGET,
    },
    {
        name: 'building_number',
        title: 'Будинок',
        widget: TEXT_WIDGET,
    },
    {
        name: 'residential_complex',
        title: 'ЖК',
        widget: TEXT_WIDGET,
    },
    {
        name: 'metro_stations',
        title: 'Метро',
        widget: (props: ColumnDefProps) => {
            return (
                <MetroComponent
                    value={props.value as MetroStation[] || []}
                    onChange={props.onChange}
                />
            );
        },
    },
    {
        name: 'side',
        title: 'Берег',
        options: ["Лівий","Правий"],
        widget: SELECT_WIDGET,
    },
    {
        name: 'rooms',
        title: 'Кімнати',
        options: [1,2,3,4,5,6],
        widget: SELECT_WIDGET,
    },
    {
    // Todo: think about use amount of floor value as a base for select for floor
        name: 'floor',
        title: 'Поверх',
        widget: NUMERICAL_WIDGET,
    },
    {
        name: 'amount_of_floors',
        title: 'Поверхневість',
        widget: NUMERICAL_WIDGET,
    },
    {
        name: 'living_area',
        title: 'Площа',
        widget: NUMERICAL_WIDGET,
    },
    {
        name: 'rent_price',
        title: 'Ціна',
        widget: NUMERICAL_WIDGET,
    },
    {
        name: 'currency',
        title: 'Валюта',
        options: ["UAH","USD","EUR"],
        widget: SELECT_WIDGET,
    },
    {
        name: 'kids',
        title: 'Діти',
        options: ["Діти","Без Дітей"],
        widget: SELECT_WIDGET,
    },
    {
        name: 'pets',
        title: 'Тварини',
        options: ["Пес","Кіт","Всі", "Без Тварин"],
        widget: SELECT_WIDGET,
    },

    {
        name: 'geo',
        title: 'Гео',
        widget: URL_WIDGET,
    },

    {
        name: 'source_created_at',
        title: 'Опубліковано',
        widget: DATE_FROM_NOW_WIDGET,
    },

    {
        name: 'created_at',
        title: 'Спаршено',
        widget: DATE_FROM_NOW_WIDGET,
    },
    {
        name: 'source_id',
        title: 'OLX-ID',
        widget: DISPLAY_WIDGET,
    },


]

export const ParsingResultItem = ({column}: ParsingResultItemProps) => {
    const [parsedAd, setParsedAd] = useState<ObjectModel>({
        id: column?.id || '',
        district: column?.district || '',
        street: column?.street || '',
        rent_price: column?.rent_price || 0,
        currency: column?.currency || '',
        rooms: column?.rooms || 0,
        floor: column?.floor || 0,
        living_area: column?.living_area || 0,
        amount_of_floors: column?.amount_of_floors || 0,
        building_number: column?.building_number || '',
        residential_complex: column?.residential_complex || '',
        side: column?.side || '',
        kids: column?.side || '',
        pets: column?.side || '',
        geo: column?.side || '',
        is_center: column?.is_center || false,
        newly_built: column?.newly_built || false,
        metro_stations: column?.metro_stations || [],
        images: column?.images || [],
        source_created_at: column?.source_created_at || '',
        created_at: column?.created_at || '',
        source_id: column?.source_id || '',

    });
    const dispatch = useDispatch();
    const theme = useTheme();

    return (
        <Card>
            <CardContent>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography
                            variant="h4"
                            component="div"
                            align={"center"}
                            sx={{mb: 1, cursor: "pointer"}}
                            onClick={() => window.open(
                                column.source_link,
                                "_blank",
                                "location=yes,height=1080,width=1920,scrollbars=yes,status=yes"
                            )}
                        >
                            {column?.source_label || "Заголовок"}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => window.open(column.source_link, "_blank")}
                            sx={{marginLeft: 1}}
                            aria-label="open in new window"
                        >
                            <OpenInNewIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container>
                    <Masonry columns={5}>
                        {COLUMNS.map((def, key) => {
                            const value = parsedAd[def.name]
                            return (
                                <Box>
                                    <def.widget key={key} title={def.title} value={value} options={def.options} onChange={(value) => {
                                        setParsedAd(prevState => ({
                                            ...prevState,
                                            [def.name]: value,
                                        }))
                                    }}></def.widget>
                                </Box>
                            )
                        })}
                    </Masonry>
                    <Grid item xs={12} container direction="row">
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.street*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Вулиця:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.street}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                false*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Будинок:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.livingArea*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Площа:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.livingArea}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.rooms*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Кімнат:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.rooms}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.floor*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Поверх:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.floor}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.floorAmount*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Поверхневість:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.floorAmount}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.residentialComplex*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            ЖК:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.residentialComplex}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.price*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Ціна:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.price}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.currency*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Валюта:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.currency}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.contactName*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Контактна особа:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.contactName}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.phoneNumber*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Телефон:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.phoneNumber}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.kids*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Діти:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.kids}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.pets*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Тварини:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.pets}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.sourceCreatedAt*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Створено на OLX:*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column?.sourceCreatedAt*/}
                        {/*                ? dayjs(column.sourceCreatedAt).fromNow()*/}
                        {/*                : ""}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.createdAt*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            Спарсили:*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column?.createdAt*/}
                        {/*                ? dayjs(column.createdAt).fromNow()*/}
                        {/*                : ""}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4}>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            color={*/}
                        {/*                column.sourceId*/}
                        {/*                    ? "textSecondary"*/}
                        {/*                    : theme.palette.error.light*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            OLX ID:{" "}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            onKeyDown={handleKeyDown}*/}
                        {/*            color="textSecondary"*/}
                        {/*            suppressContentEditableWarning={true}*/}
                        {/*            contentEditable={true}*/}
                        {/*            style={{marginLeft: "8px"}}*/}
                        {/*            onBlur={(val) => console.log(val)}*/}
                        {/*        >*/}
                        {/*            {column.sourceId}*/}
                        {/*        </Typography>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 2,
                        }}
                    >
                        {1 !== 1 && <ActionLink label="Зберегти в базу" bgColor="#1976d2"/>}
                        <ActionLink label="Відкласти" bgColor="#4719d2"/>
                        <ActionLink label="В смітник" bgColor="#1789d5"/>
                        <ActionLink label="Дублікат" bgColor="#a217d9"/>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

