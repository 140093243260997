import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ModalSliceState {
  images: string[];
  modals: [
    {
      id: string;
      isOpened: boolean;
      type: string;
    }
  ];
  editMode: boolean;
  showingMode: boolean;
  showingModeURL: string;
  currentModalId: string;
}

const initialState = {} as ModalSliceState;

const ModalPopupSlice = createSlice({
  name: "modal-popup",
  initialState,
  reducers: {
    addModal(
      state,
      action: PayloadAction<{ id: string; isOpened: boolean; type: string }>
    ) {
      state.modals.push({
        id: action.payload.id,
        isOpened: action.payload.isOpened,
        type: action.payload.type,
      });
    },
    updateModalWithId(
      state,
      action: PayloadAction<{ id: string; isOpened: boolean; type: string }>
    ) {
      state.modals.map((modal) => {
        if (modal.id === action.payload.id) {
          modal.isOpened = action.payload.isOpened;
          modal.type = action.payload.type;
        }
      });
    },
    getCurrentOpenedModal(state) {
      state.currentModalId = state.modals.filter(
        (modal) => modal.isOpened === true
      )[0].id;
    },
    setEditMode(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
    },
    setEditImages(state, action: PayloadAction<string[]>) {
      state.images = action.payload;
    },
    setShowingMode(state, action: PayloadAction<boolean>) {
      state.showingMode = action.payload;
    },
    setShowingModeURL(state, action: PayloadAction<string>) {
      state.showingModeURL = action.payload;
    },
  },
});

export const {
  addModal,
  updateModalWithId,
  setEditMode,
  setEditImages,
  setShowingMode,
  getCurrentOpenedModal,
  setShowingModeURL,
} = ModalPopupSlice.actions;
export default ModalPopupSlice.reducer;
