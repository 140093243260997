import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  isLoading: boolean;
  isApiDetected: boolean;
  toggleTypeOfProperty: boolean | null;
}

const initialState = {
  isLoading: false,
  isApiDetected: false,
  toggleTypeOfProperty: null,
} as AppState;

const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsApiDetected(state, action: PayloadAction<boolean>) {
      state.isApiDetected = action.payload;
    },
    setToggleTypeOfProperty(state, action: PayloadAction<boolean | null>) {
      state.toggleTypeOfProperty = action.payload;
    },
  },
});

export const { setIsLoading, setToggleTypeOfProperty } = AppSlice.actions;
export default AppSlice.reducer;
