import React from "react";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Input,
  Link,
  Typography,
} from "@mui/material";

import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
const rows: GridRowsProp = [
  {
    id: 1,
    name: randomTraderName(),
    age: 25,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 2,
    name: randomTraderName(),
    age: 36,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 3,
    name: randomTraderName(),
    age: 19,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 4,
    name: randomTraderName(),
    age: 28,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 5,
    name: randomTraderName(),
    age: 23,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
];
type AdsViewProps = {
  item: any;
};
const AdsView = (props: AdsViewProps) => {
  // TODO using this props for feature
  const [editMode, setEditMode] = React.useState(false);
  const images = [
    { thumbnail: { uri: "https://placehold.co/600x400", name: "1" } },
    { thumbnail: { uri: "https://placehold.co/600x400", name: "2" } },
    { thumbnail: { uri: "https://placehold.co/600x400", name: "3" } },
    { thumbnail: { uri: "https://placehold.co/600x400", name: "4" } },
    { thumbnail: { uri: "https://placehold.co/600x400", name: "5" } },
    { thumbnail: { uri: "https://placehold.co/600x400", name: "6" } },
    { thumbnail: { uri: "https://placehold.co/600x400", name: "7" } },
    { thumbnail: { uri: "https://placehold.co/600x400", name: "8" } },
  ];
  return (
    <>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="div"
            align={"right"}
            sx={{ mb: 1 }}
          >
            <Link
              href={"../apartment/1"}
              style={{
                color: "#fff",
                textDecoration: "none",
                backgroundColor: "#eac401",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              Редагувати фото
            </Link>
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <ImageList
                sx={{
                  gridAutoFlow: "column",
                  gridTemplateColumns:
                    "repeat(auto-fill,minmax(400px,1fr)) !important",
                  gridAutoColumns: "minmax(400px, 1fr)",
                }}
              >
                {images.map((image) => (
                  <ImageListItem>
                    <img src={image.thumbnail.uri} />
                    <ImageListItemBar title={image.thumbnail.name} />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={6}>
              <Typography variant="h4" component="div" align={"left"}>
                Загальна інформація
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" component="div" align={"right"}>
                <Link
                  onClick={() => setEditMode((prevState) => !prevState)}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    backgroundColor: editMode ? "#016eea" : "#eac401",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {editMode ? "Зберегти" : "Редагувати"}
                </Link>
              </Typography>
            </Grid>
          </Grid>

          {!editMode ? (
            <FormControl>
              <Grid item xs={12} container direction="row">
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    Район: {rows[0].region}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    Вулиця: {rows[0].street}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    Будинок: {}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    Площа: {rows[0].livingArea}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    Кімнат: {rows[0].rooms}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    Поверх: {rows[0].floor}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    Поверхневість: {rows[0].floorAmount}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    ЖК: {rows[0].residentialComplex}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    Ціна: {rows[0].price}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    Валюта: {rows[0].currency}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    Контактна особа: {rows[0].contactName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    Телефон: {rows[0].phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    Діти: {rows[0].kids}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom>
                    Тварини: {rows[0].pets}
                  </Typography>
                </Grid>
              </Grid>
            </FormControl>
          ) : (
            <Grid item xs={12} container direction="row">
              <Grid item xs={4}>
                <Input placeholder="Район" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Вулиця" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Будинок" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Площа" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Кімнат" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Поверх" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Поверхневість" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="ЖК" />
              </Grid>

              <Grid item xs={4}>
                <Input placeholder="Ціна" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Валюта" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Контактна особа" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Телефон" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Діти" />
              </Grid>
              <Grid item xs={4}>
                <Input placeholder="Тварини" />
              </Grid>
            </Grid>
          )}
          <Typography
            variant="h4"
            component="div"
            align={"left"}
            sx={{ mt: 1 }}
          >
            Історія змін
          </Typography>
          <Typography color="textSecondary">Зміни відсутні</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default AdsView;
