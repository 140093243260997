import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { AppBar, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useUser } from "../hooks/useUser";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import LightModeIcon from "@mui/icons-material/LightMode";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { useNavigate } from "react-router-dom";
import { ToggleOffOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setToggleTypeOfProperty } from "../redux/slices/AppSlice";

function Header() {
  const { theme, setTheme } = useTheme();
  const user = useUser();
  const navigate = useNavigate();

  let city = "Усі оголошення";

  if (user && user.city) {
    switch (user.city) {
      case "lviv":
        city = "Львів";
        break;
      case "kyiv":
        city = "Київ";
        break;
    }
  }

  const dispatch = useDispatch();
  const pages = [[]];
  //["/", "Головна"]
  const profilePages: string[][] = [];
  if (!user) {
    profilePages.push(["/sign-in", "Увійти"]);
  } else {
    // if (user.roles.includes("admin")) {
    //   profilePages.push(["/admin", "Admin"]);
    // }
    //profilePages.push(["/profile", "Профіль"]);
    profilePages.push(["/sign-out", "Вийти"]);
  }

  const [typeOfProperty, setTypeOfProperty] = React.useState<null | boolean>(
    null
  );
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const navigateTo = (path: string) => {
    navigate(path);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleTypeChange = (tool: boolean) => {
    setTypeOfProperty(tool);
    localStorage.setItem("typeOfProperty", tool.toString());
    dispatch(setToggleTypeOfProperty(tool));
  };

  useEffect(() => {
    setTypeOfProperty(localStorage.getItem("typeOfProperty") === "true");
  }, []);
  return (
    <AppBar position="static" color="default" elevation={1}>
      <Container maxWidth="xl" sx={{ px: { xs: 0, sm: 3 } }}>
        <Toolbar disableGutters>
          <MapsHomeWorkIcon
            sx={{ display: { xs: "none", md: "flex" }, fontSize: 40, mr: 1 }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Real Estate
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Tooltip title="Головне меню">
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((p) => (
                <MenuItem
                  key={p[0]}
                  onClick={handleCloseNavMenu}
                  component={RouterLink}
                  to={p[0]}
                >
                  <Typography textAlign="center">{p[1]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <MapsHomeWorkIcon
            sx={{ display: { xs: "flex", md: "none" }, mr: 1, fontSize: 40 }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Real Estate
          </Typography>
          {localStorage.getItem("token") !== null && (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  placeContent: "start",
                  display: { xs: "none", md: "flex" },
                  mr: 2,
                }}
              >
                <Button
                  onClick={() => navigateTo("/parser")}
                  sx={{ display: "block" }}
                >
                  Парсер
                </Button>
                <Button
                  onClick={() => navigateTo("/base")}
                  sx={{ display: "block" }}
                >
                  База
                </Button>
              </Box>
              <Box sx={{ flexGrow: 2, display: "flex" }}>
                <Typography>{city}</Typography>
              </Box>

              <Box sx={{ flexGrow: 2, display: "flex", gap: 2 }}>
                <Typography
                  onClick={() => handleTypeChange(true)}
                  sx={{
                    cursor: "pointer",
                    color: `${typeOfProperty ? "#90caf9" : ""}`,
                  }}
                >
                  Продаж
                </Typography>
                /
                <Typography
                  onClick={() => handleTypeChange(false)}
                  sx={{
                    cursor: "pointer",
                    color: `${typeOfProperty ? "" : "#90caf9"}`,
                  }}
                >
                  Оренда
                </Typography>
              </Box>
            </>
          )}

          <Box
            sx={{
              flexGrow: 1,
              placeContent: "end",
              display: { xs: "none", md: "flex" },
              mr: 2,
            }}
          >
            {pages.map((page) => (
              <Button
                key={page[0]}
                onClick={handleCloseNavMenu}
                href={page[0]}
                sx={{ my: 2, px: 1, display: "block" }}
              >
                {page[1]}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Профіль користувача">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircleIcon fontSize={"large"} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {profilePages.map((p) => (
                <MenuItem
                  key={p[0]}
                  onClick={handleCloseUserMenu}
                  component={RouterLink}
                  to={p[0]}
                >
                  <Typography textAlign="center">{p[1]}</Typography>
                </MenuItem>
              ))}
            </Menu>

            <IconButton aria-label={"Тема"} sx={{ ml: 1 }} color="inherit">
              <ToggleOffOutlined />
            </IconButton>
            <IconButton
              aria-label={"Тема"}
              sx={{ ml: 1 }}
              onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
              color="inherit"
            >
              {theme === "dark" ? <LightModeIcon /> : <ModeNightIcon />}
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
