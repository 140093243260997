export type ParsedAdFilter = 'all' | 'owners' | 'priceChangeUp' | 'priceChangeDown' | 'postponed' | 'trash';

export const PARSED_AD_FILTERS: Record<ParsedAdFilter, Record<string, string>> = {
  "all":{ contact_is_owner: 'False', object_already_exist: 'False', is_postponed: 'False', is_trash: 'False' },
  "owners":{ contact_is_owner: 'True', is_postponed: 'False', is_trash: 'False', object_already_exist: 'False'},
  "priceChangeUp":{price_change: 'up', object_already_exist: 'True', is_postponed: 'False', is_trash: 'False' },
  "priceChangeDown": { price_change: 'down', object_already_exist: 'True', is_postponed: 'False', is_trash: 'False' },
  "postponed":{ is_postponed: 'True', is_trash: 'False' },
  "trash":{is_postponed: 'False', is_trash: 'True' },
}
export type ParsingProps = {
  title: string;
  filter: ParsedAdFilter;
};
export type ImageProps = {
  imageUrl?: string;
  onEdit: () => void;
};


export type ParsedAdSortOrderDate = 'new' | 'old' | 'new_olx' | 'old_olx';
