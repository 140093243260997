import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { User, UserRole } from "../../types";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";

interface UserFormProps {
  user?: User | null;
  onSubmit: (data: UserFormData) => void;
  formErrors: { [key: string]: string[] };
}

export interface UserFormData {
  id?: number | string;
  full_name: string;
  email: string;
  telegram: string;

  instagram: string;
  description: string;

  facebook: string;
  twitter: string;

  is_active: boolean;

  roles: UserRole[];
}

export const UserForm = ({ user, onSubmit, formErrors }: UserFormProps) => {
  const [formData, setFormData] = useState<UserFormData>(
    user && "id" in user
      ? {
          id: user.id,
          full_name: user.full_name,
          email: user.email,
          telegram: user.telegram,
          instagram: user.instagram,
          facebook: user.facebook,
          twitter: user.twitter,
          description: user.description,
          is_active: user.is_active,
          roles: user.roles,
        }
      : {
          full_name: "",
          email: "",
          telegram: "",
          instagram: "",
          facebook: "",
          twitter: "",
          description: "",
          is_active: false,
          roles: [],
        }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit(formData);
  };

  const roles = ["admin", "user", "volunteer"];

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Full name"
        name="full_name"
        value={formData.full_name}
        onChange={handleChange}
        error={Boolean(formErrors.first_name)}
        helperText={formErrors.first_name && formErrors.first_name[0]}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        error={Boolean(formErrors.email)}
        helperText={formErrors.email && formErrors.email[0]}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Telegram"
        name="telegram"
        value={formData.telegram}
        onChange={handleChange}
        error={Boolean(formErrors.telegram)}
        helperText={formErrors.telegram && formErrors.telegram[0]}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Instagram"
        name="instagram"
        value={formData.instagram}
        onChange={handleChange}
        error={Boolean(formErrors.instagram)}
        helperText={formErrors.instagram && formErrors.instagram[0]}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Facebook"
        name="facebook"
        value={formData.facebook}
        onChange={handleChange}
        error={Boolean(formErrors.facebook)}
        helperText={formErrors.facebook && formErrors.facebook[0]}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Twitter"
        name="twitter"
        value={formData.twitter}
        onChange={handleChange}
        error={Boolean(formErrors.twitter)}
        helperText={formErrors.twitter && formErrors.twitter[0]}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        error={Boolean(formErrors.description)}
        helperText={formErrors.description && formErrors.description[0]}
        fullWidth
        multiline
        margin="normal"
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Roles</InputLabel>
        <Select
          label="Roles"
          name="roles"
          multiple
          value={formData.roles}
          onChange={(e) => {
            const selectedRoles = e.target.value as string[];
            const newRoles = roles.filter((r) =>
              selectedRoles.includes(r)
            ) as UserRole[];
            setFormData((prevFormData) => ({
              ...prevFormData,
              roles: newRoles,
            }));
          }}
          fullWidth
        >
          {roles.map((tag) => (
            <MenuItem key={tag} value={tag}>
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <FormControlLabel
          control={
            <Switch
              checked={formData.is_active}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  is_active: e.target.checked,
                }))
              }
            />
          }
          label="Active"
          labelPlacement="end"
        />
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: "1.5rem" }}
      >
        Save
      </Button>
    </form>
  );
};
