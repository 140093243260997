import { LoginData, RegisterData, UserModel } from "../types";
import api from "./server";

interface AuthResponse {
  access_token: string;
  token_type: string;
}

export async function register(data: RegisterData): Promise<AuthResponse> {
  const response = await api.post(`/auth/register`, data);
  return response.data;
}

export async function login(data: LoginData): Promise<AuthResponse> {
  let formData = new FormData();
  formData.append("username", data.username);
  formData.append("password", data.password);
  const response = await api.post(`/auth/jwt/login`, formData);
  return response.data;
}

export async function getProfile(token: string): Promise<UserModel> {
  const response = await api.get(`/users/identity`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
