import axios from "axios";

export const DEFAULT_API_URL = process.env.REACT_APP_API_SERVER;

export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return `Bearer ${token}`;
  }
  return "";
};

const api = axios.create({
  baseURL: DEFAULT_API_URL,
});

// add a request interceptor to attach the JWT to each request
api.interceptors.request.use((config) => {
  const authHeader = getAuthHeader();
  if (authHeader) {
    config.headers.Authorization = authHeader;
  }
  return config;
});
api.interceptors.response.use(
  (value) => {
    return value;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      !error.config.url.startsWith("/auth/login")
    ) {
      localStorage.removeItem("token");
      window.location.pathname = "/";
    }
    return Promise.reject(error);
  }
);

export default api;
