import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { getProfile } from "../api";
import { UserModel } from "../types";

export const useUser = () => {
  const { token } = useAuth();
  const [user, setUser] = useState<UserModel | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getProfile(token!);

        setUser(response as UserModel);
      } catch (error) {
        console.error(error);
      }
    };

    if (token) {
      fetchUser();
    }
  }, [token]);

  return user;
};
