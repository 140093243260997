import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "../services/auth";
import AppSlice from "./slices/AppSlice";
import AuthSlice from "./slices/AuthSlice";
import ModalPopupSlice from "./slices/ModalPopupSlice";
import { parsedAdApi } from "../services/data";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [parsedAdApi.reducerPath]: parsedAdApi.reducer,
    app: AppSlice,
    auth: AuthSlice,
    modals: ModalPopupSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(parsedAdApi.middleware)
      .concat(authApi.middleware),
});

setupListeners(store.dispatch);
