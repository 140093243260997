import React, { ChangeEvent, FormEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { User } from "../../types";
import { InputAdornment } from "@mui/material";

export interface FormData {
  full_name: string;
  email: string;
  telegram: string;
  instagram: string;
  facebook: string;
  twitter: string;
  description: string;
}

export interface ProfileFormProps {
  user: User;

  onSubmit: (data: FormData) => void;
  formErrors: Array<{ field: keyof FormData; error: string }>;
}

const ProfileEdit = ({ user, onSubmit, formErrors }: ProfileFormProps) => {
  const [formData, setFormData] = useState<FormData>({
    full_name: user.full_name,
    email: user.email,
    telegram: user.telegram,
    instagram: user.instagram,
    facebook: user.facebook,
    twitter: user.twitter,
    description: user.description,
  });

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formData as User);
  };

  const getFieldErrors = (fieldName: string): string[] => {
    return formErrors
      .filter((error) => error.field === fieldName)
      .map((error) => error.error);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <TextField
        label="Прізвище Імʼя"
        name="full_name"
        value={formData.full_name}
        onChange={handleFormChange}
        margin="normal"
        variant="outlined"
        fullWidth
        required
        error={Boolean(getFieldErrors("first_name").length)}
        helperText={getFieldErrors("first_name").join(", ")}
      />
      <TextField
        label="Про себе"
        name="description"
        value={formData.description || ""}
        onChange={handleFormChange}
        margin="normal"
        variant="outlined"
        multiline
        fullWidth
        error={Boolean(getFieldErrors("description").length)}
        helperText={
          getFieldErrors("description").join(", ") ||
          "Короткий опис про вашу діяльність (можна додавати посилання)"
        }
      />
      <TextField
        label="Telegram"
        name="telegram"
        value={formData.telegram || ""}
        onChange={handleFormChange}
        margin="normal"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ mr: 0 }} position="start">
              https://t.me/
            </InputAdornment>
          ),
        }}
        error={Boolean(getFieldErrors("telegram").length)}
        helperText={
          getFieldErrors("telegram").join(", ") || "Введіть лише ваш нік-нейм"
        }
      />
      <TextField
        label="Instagram"
        name="instagram"
        value={formData.instagram || ""}
        onChange={handleFormChange}
        margin="normal"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ mr: 0 }} position="start">
              https://instagram.com/
            </InputAdornment>
          ),
        }}
        error={Boolean(getFieldErrors("instagram").length)}
        helperText={
          getFieldErrors("instagram").join(", ") || "Введіть лише ваш нік-нейм"
        }
      />
      <TextField
        label="Facebook"
        name="facebook"
        value={formData.facebook || ""}
        onChange={handleFormChange}
        margin="normal"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ mr: 0 }} position="start">
              https://facebook.com/
            </InputAdornment>
          ),
        }}
        error={Boolean(getFieldErrors("facebook").length)}
        helperText={
          getFieldErrors("facebook").join(", ") || "Введіть лише ваш нік-нейм"
        }
      />
      <TextField
        label="Twitter"
        name="twitter"
        value={formData.twitter || ""}
        onChange={handleFormChange}
        margin="normal"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ mr: 0 }} position="start">
              https://twitter.com/
            </InputAdornment>
          ),
        }}
        error={Boolean(getFieldErrors("twitter").length)}
        helperText={
          getFieldErrors("twitter").join(", ") || "Введіть лише ваш нік-нейм"
        }
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email || ""}
        onChange={handleFormChange}
        margin="normal"
        variant="outlined"
        fullWidth
        error={Boolean(getFieldErrors("email").length)}
        helperText={getFieldErrors("email").join(", ")}
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 1 }}>
        Оновити дані
      </Button>
    </form>
  );
};

export default ProfileEdit;
