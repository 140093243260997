import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { randomPhoneNumber, randomUrl } from "@mui/x-data-grid-generator";
import {
  randomId,
  randomInt,
  randomPrice,
  randomUserName,
} from "@mui/x-data-grid-generator/services/random-generator";
import Carousel from "react-material-ui-carousel";
import EditIcon from "@mui/icons-material/Edit";
import PopupMenu from "../../components/parser/popup_menu";
import { parsedAdApi } from "../../services/data";
import { useDispatch, useSelector } from "react-redux";
import { setEditMode } from "../../redux/slices/ModalPopupSlice";

const columns: GridColDef[] = [
  {
    field: "region",
    headerName: "Район",
    minWidth: 10,
    maxWidth: 800,
    type: "singleSelect",
    valueOptions: [
      "Галицький",
      "Франківський",
      "Сихівський",
      "Шевченківський",
      "Личаківський",
      "Залізничий",
    ],
    editable: true,
  },
  {
    field: "street",
    headerName: "Вулиця",
    minWidth: 10,
    maxWidth: 800,
    editable: true,
  },
  {
    field: "residentialComplex",
    headerName: "Жк",
    minWidth: 10,
    maxWidth: 400,
    type: "singleSelect",
    valueOptions: [
      "Жк Галицький",
      "Жк Франківський",
      "Жк Сихівський",
      "Жк Шевченківський",
      "Жк Личаківський",
      "Жк Залізничий",
    ],
    editable: true,
  },
  {
    field: "rooms",
    headerName: "Кімнати",
    minWidth: 10,
    maxWidth: 30,
    type: "number",
    editable: true,
  },
  {
    field: "livingArea",
    minWidth: 10,
    maxWidth: 40,
    headerName: "Площа",
    type: "number",
    editable: true,
  },
  {
    field: "floor",
    headerName: "Поверх",
    minWidth: 1,
    maxWidth: 40,
    type: "number",
    editable: true,
  },
  {
    field: "floorAmount",
    headerName: "Поверхневість",
    minWidth: 1,
    maxWidth: 40,
    type: "number",
    editable: true,
  },
  {
    field: "price",
    headerName: "Ціна",
    minWidth: 1,
    maxWidth: 500,
    type: "number",
    editable: true,
  },
  {
    field: "currency",
    headerName: "Валюта",
    minWidth: 1,
    maxWidth: 100,
    type: "singleSelect",
    valueOptions: ["USD", "EUR", "UAH"],
    editable: true,
  },
  {
    field: "contactName",
    headerName: "Контакт",
    minWidth: 1,
    maxWidth: 700,
    editable: true,
  },
  {
    field: "phoneNumber",
    headerName: "Телефон",
    minWidth: 1,
    maxWidth: 100,
    type: "number",
    editable: true,
  },
  {
    field: "ownershipStatus",
    headerName: "Статус",
    minWidth: 1,
    maxWidth: 50,
    type: "singleSelect",
    valueOptions: ["В", "Р"],
    editable: true,
  },
  {
    field: "kids",
    headerName: "Діти",
    minWidth: 1,
    maxWidth: 50,
    type: "singleSelect",
    valueOptions: ["УД", "БД", "Д6"],
    editable: true,
  },
  {
    field: "pets",
    headerName: "Тварини",
    minWidth: 1,
    maxWidth: 50,
    type: "singleSelect",
    valueOptions: ["УТ", "БТ", "К", "П"],
    editable: true,
  },
  {
    field: "geo",
    headerName: "Гео",
    minWidth: 1,
    maxWidth: 100,
    editable: true,
  },
  {
    field: "origin",
    headerName: "Посилання на оригінал",
    minWidth: 1,
    maxWidth: 100,
    editable: false,
  },
  {
    field: "originId",
    headerName: "ID",
    minWidth: 1,
    maxWidth: 500,
    type: "number",
    editable: false,
  },
  {
    field: "ourObject",
    headerName: "Наш Обʼєкт",
    editable: false,
  },
];

const rows: GridRowsProp = [
  {
    id: 1,
    region: "Галицький",
    street: "any street",
    residentialComplex: "ЖК Галицький",
    rooms: randomInt(1, 5),
    livingArea: randomInt(40, 120),
    floor: randomInt(1, 30),
    floorAmount: randomInt(1, 30),
    price: randomPrice(10000, 40000),
    currency: "UAH",
    contactName: randomUserName(),
    phoneNumber: randomPhoneNumber(),
    ownershipStatus: "В",
    kids: "УД",
    pets: "УТ",
    geo: randomUrl(),
    origin: randomUrl(),
    originId: randomId(),
  },
  {
    id: 2,
    region: "Галицький",
    street: "any street",
    residentialComplex: "ЖК Галицький",
    rooms: randomInt(1, 5),
    livingArea: randomInt(40, 120),
    floor: randomInt(1, 30),
    floorAmount: randomInt(1, 30),
    price: randomPrice(10000, 40000),
    currency: "UAH",
    contactName: randomUserName(),
    phoneNumber: randomPhoneNumber(),
    ownershipStatus: "Власник",
    kids: "БД",
    pets: "БТ",
    geo: randomUrl(),
    origin: randomUrl(),
    originId: randomId(),
  },
  {
    id: 3,
    region: "Галицький",
    street: "any street",
    residentialComplex: "ЖК Галицький",
    rooms: randomInt(1, 5),
    livingArea: randomInt(40, 120),
    floor: randomInt(1, 30),
    floorAmount: randomInt(1, 30),
    price: randomPrice(10000, 40000),
    currency: "UAH",
    contactName: randomUserName(),
    phoneNumber: randomPhoneNumber(),
    ownershipStatus: "Власник",
    kids: "Д6",
    pets: "К",
    geo: randomUrl(),
    origin: randomUrl(),
    originId: randomId(),
  },
];
type ParsingProps = {
  title: string;
  filter: string;
};
type ItemProps = {
  name: string;
  description: string;
  color: string;
  href: string;
};

function Project({ item }: { item: ItemProps }) {
  const dispatch = useDispatch();
  return (
    <Paper
      className="Project"
      style={{
        backgroundColor: item.color,
        height: 200,
        position: "relative",
      }}
      elevation={10}
    ></Paper>
  );
}
function Example() {
  interface SettingsT {
    autoPlay: boolean;
    animation: "fade" | "slide";
    indicators: boolean;
    duration: number;
    navButtonsAlwaysVisible: boolean;
    navButtonsAlwaysInvisible: boolean;
    fullHeightHover: boolean;
    cycleNavigation: boolean;
    swipe: boolean;
    [key: string]: any;
  }
  const DefaultSettingsT: SettingsT = {
    autoPlay: false,
    animation: "fade",
    indicators: true,
    duration: 500,
    navButtonsAlwaysVisible: true,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    fullHeightHover: true,
    swipe: true,
  };
  const items = [
    {
      name: "Lear Music Reader",
      description: "A PDF Reader specially designed for musicians.",
      color: "#64ACC8",
      href: "https://github.com/Learus/Lear-Music-Reader",
    },
    {
      name: "Hash Code 2019",
      description:
        "My Solution on the 2019 Hash Code by Google Slideshow problem.",
      color: "#7D85B1",
      href: "https://github.com/Learus/HashCode2019",
    },
    {
      name: "Terrio",
      description: "A exciting mobile game game made in the Unity Engine.",
      color: "#CE7E78",
      href: "https://play.google.com/store/apps/details?id=com.Brewery.Terrio",
    },
    {
      name: "React Carousel",
      description:
        "A Generic carousel UI component for React using material ui.",
      color: "#C9A27E",
      href: "https://github.com/Learus/react-material-ui-carousel",
    },
  ];

  return (
    <Carousel
      className={"slider"}
      navButtonsWrapperProps={{
        style: {
          height: 24,
          position: "absolute",
          top: "auto",
          bottom: 16,
        },
      }}
      navButtonsProps={{
        style: {
          height: 24,
          width: 24,
        },
      }}
      indicatorIconButtonProps={{
        style: {
          display: "none",
        },
      }}
      {...DefaultSettingsT}
    >
      {items.map((item, i) => (
        <Project key={i} item={item} />
      ))}
    </Carousel>
  );
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BaseResult = (props: ParsingProps) => {
  const { data } = parsedAdApi.useGetParsedDataQuery({});

  const modals = useSelector((state: any) => state.modals);
  const dispatch = useDispatch();
  const [ResidentalComplex, setResidentalComplex] = useState("1");
  const [Rooms, setRooms] = useState("1");
  const { title, filter } = props; // TODO add filter for parser result owner/default ROLES

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  const closePopup = async () => {
    await dispatch(setEditMode(false));
  };
  const TextFieldPropsForFilter = {
    style: {
      // TODO Sizes
    },
  };
  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        {title}
      </Typography>
      <TextField
        size="small"
        InputProps={TextFieldPropsForFilter}
        id="outlined-basic"
        label="По назві"
        variant="outlined"
      />
      <TextField
        size="small"
        id="outlined-basic"
        label="По району"
        variant="outlined"
      />
      <TextField
        size="small"
        id="outlined-basic"
        label="По вулиці"
        variant="outlined"
      />
      <Select
        size="small"
        defaultValue={ResidentalComplex}
        value={ResidentalComplex}
        onChange={(e) => setResidentalComplex(e.target.value)}
      >
        <MenuItem>
          <em>None</em>
        </MenuItem>
        <MenuItem value={1}>Галицький</MenuItem>
        <MenuItem value={2}>Волинський</MenuItem>
        <MenuItem value={3}>Центральний</MenuItem>
      </Select>
      <TextField
        size="small"
        id="outlined-basic"
        label="По ціні"
        variant="outlined"
      />
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={Rooms}
        size="small"
        onChange={(e) => setRooms(e.target.value)}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={3}>4</MenuItem>
      </Select>{" "}
      <TextField
        size="small"
        id="outlined-basic"
        label="По поверху"
        variant="outlined"
      />
      <TextField
        size="small"
        id="outlined-basic"
        label="По площі"
        variant="outlined"
      />
      <Grid container spacing={1}>
        {columns.map((column, index) => {
          return (
            <Grid item key={index} style={{ width: "100%" }}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="div"
                    align={"center"}
                    sx={{ mb: 1 }}
                  >
                    Заголовок
                  </Typography>
                  <Grid container>
                    <Grid item xs={2}>
                      <Example />
                    </Grid>
                    <Grid item xs={8} container direction="row">
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">Район: </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].region}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Вулиця:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].street}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Будинок:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].livingArea}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Кімнат:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].rooms}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Поверх:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].floor}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Поверхневість:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].floorAmount}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">ЖК: </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].residentialComplex}
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">Ціна: </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].price}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Валюта:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].currency}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Контактна особа:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].contactName}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Телефон:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].phoneNumber}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">Діти: </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].kids}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography color="textSecondary">
                            Тварини:{" "}
                          </Typography>
                          <Typography
                            onKeyDown={handleKeyDown}
                            color="textSecondary"
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            style={{ marginLeft: "8px" }}
                            onBlur={(val) => console.log(val)}
                          >
                            {rows[0].pets}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        // position column
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Link
                        style={{
                          color: "#fff",
                          textDecoration: "none",
                          backgroundColor: "#1976d2",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          fontWeight: 600,
                          textTransform: "uppercase",
                        }}
                        href={"/apartment/1"}
                      >
                        Переглянути
                      </Link>
                    </Grid>
                    <Grid item xs={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Modal
        open={modals?.editMode}
        onClose={closePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PopupMenu closePopup={() => closePopup} images={[]} />
        </Box>
      </Modal>
    </>
  );
};
export default BaseResult;
